<template>
	<div>
		<div class="max-w-xl mx-auto mx-3 mb-2 rounded">
			<restaurant-table
				:restaurantId="$route.params.restaurantId"
				:restaurantName="restaurant.name"
				:withTableSelector="
					additionalInfo && additionalInfo.tableId ? false : true
				"
				v-model="tableForm"
				v-if="!additionalInfo || !additionalInfo.tableId"
			/>
		</div>
		<form class="max-w-xl mx-auto mx-3 p-4 bg-white shadow rounded">
			<p class="text-grey-800 font-medium">+ Personal informations</p>
			<div v-show="!user" class="">
				<label class="block text-sm text-grey-00" for="cus_name">
					Name
				</label>
				<input
					class="w-full p-3 py-1 text-grey-700 bg-grey-200 rounded"
					type="text"
					v-model="form.name"
					required=""
					:disabled="user ? true : false"
					placeholder="Your Name"
					aria-label="Name"
				/>
				<span class="text-danger" v-if="$root.errors.name">
					{{ $root.errors.name.join(',') }}
				</span>
			</div>
			<div v-show="!user" class="mt-2">
				<label class="block text-sm text-grey-600">E-mail</label>
				<input
					class="w-full p-3 text-grey-700 bg-grey-200 rounded"
					type="text"
					v-model="form.email"
					required=""
					:disabled="user ? true : false"
					placeholder="Your E-mail"
					aria-label="E-mail"
				/>
				<span class="text-danger" v-if="$root.errors.email">
					{{ $root.errors.email.join(',') }}
				</span>
			</div>
			<div v-show="!user" class="mt-2">
				<label class="block text-sm text-grey-600">Phone</label>
				<input
					class="w-full p-3 text-grey-700 bg-grey-200 rounded"
					type="tel"
					v-model="form.phone"
					pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
					required=""
					:disabled="user ? true : false"
					placeholder="Your Phone"
					aria-label="Phone"
				/>
				<span class="text-danger" v-if="$root.errors.phone">
					{{ $root.errors.phone.join(',') }}
				</span>
			</div>
			<div v-show="!user" v-if="!user" class="mt-2">
				<label class=" block text-sm text-grey-600">Password</label>
				<input
					class="w-full p-3 px-2 py-2 text-grey-700 bg-grey-200 rounded"
					type="password"
					required=""
					v-model="form.password"
					placeholder="Your Password"
					aria-label="Password"
				/>
				<span class="text-danger" v-if="$root.errors.password">
					{{ $root.errors.password.join(',') }}
				</span>
			</div>
			<div class="inline-block mt-2 -mx-1 pl-1 w-full">
				<label class="block text-sm text-grey-600"
					>Promotional code</label
				>
				<div class="relative">
					<input
						class="w-full p-3 px-2 py-2 text-grey-700 bg-grey-200 rounded"
						type="text"
						v-model="codePromo"
						:disabled="reduction ? true : false"
						required=""
						placeholder="Promotional code"
						aria-label="Code Postal"
					/>
					<t-button
						@click="
							!reduction
								? getPromoReduction()
								: removePromoReduction()
						"
						type="button"
						class="absolute top-0 right-0 h-full text-6 px-3"
						fixedClasses="bg-magenta"
					>
						<i v-if="!reduction" class="icofont-sale-discount" />
						<i v-else class="icofont-ui-remove"></i>
					</t-button>
				</div>
				<span class="text-danger" v-if="$root.errors.codePromo">
					{{ $root.errors.codePromo.join(',') }}
				</span>
			</div>
			<div class="mt-4">
				<a @click="validate" class="text-decoration-none">
					<div
						class="rounded shadow bg-success d-flex align-items-center p-3 text-white"
					>
						<div class="more">
							<h6 class="m-0">
								Total {{ total }} {{ currency }}
							</h6>
							<p class="small m-0">
								discount {{ reductionValue }} {{ currency }}
							</p>
						</div>
						<div class="ml-auto">
							<i class="icofont-tick-mark"></i>
						</div>
					</div>
				</a>
			</div>
		</form>
	</div>
</template>

<script>
import RestaurantTable from './component/RestaurantTable';
import { mapGetters, mapActions } from 'vuex';
import { formatDateForForm } from '@/tools/helpers';
import {
	ApiGetRestaurant,
	ApiRegisterClient,
	ApiCartSubmit,
	ApiGetPromoReduction
} from '@/tools/apiService';

export default {
	data: () => ({
		// hash: {},
		form: {},
		tableForm: {},
		restaurant: {},
		// errors: [],
		reduction: null,
		codePromo: null
	}),
	components: {
		RestaurantTable
	},
	mounted: function() {
		if (this.user) {
			this.getRestaurant();
			this.$set(this.form, 'name', this.user.name);
			this.$set(this.form, 'email', this.user.email);
			this.$set(
				this.form,
				'phone',
				this.user && this.user.client ? this.user.client.phone : null
			);
		}
	},
	methods: {
		...mapActions({
			saveToken: 'auth/saveToken',
			refreshCart: 'cart/refreshCart'
		}),
		getRestaurant: function() {
			ApiGetRestaurant(this.$route.params.restaurantId).then(response => {
				this.restaurant = response.data.restaurant;
			});
		},
		removePromoReduction: function() {
			this.codePromo = null;
			this.reduction = null;
		},
		getPromoReduction: function() {
			ApiGetPromoReduction(this.codePromo).then(response => {
				if (response.data.reduction) {
					this.reduction = response.data.reduction;
				} else {
					this.$notify.error({
						title: "This code doesn't exist or expired",
						position: 'top center'
					});
					this.reduction = null;
				}
			});
		},
		createUser: function() {
			return ApiRegisterClient(this.form).then(response => {
				this.saveToken({
					token: response.data.token
				});
			});
		},
		payCommand: function() {
			return ApiCartSubmit(this.buildSubmitObj()).then(response => {
				return response.data;
			});
		},
		buildSubmitObj: function() {
			let form = {};

			if (Object.keys(this.additionalInfo).length > 0) {
				form = this.additionalInfo;
			} else if (Object.keys(this.tableForm).length > 0) {
				form = this.tableForm;
			} else {
				form.planifiedFor = formatDateForForm(
					this.form.planifiedFor ? this.form.planifiedFor : new Date()
				);
				form.comment = '*---*';
				form.address = this.form.address;
				form.city = this.form.city ? this.form.city : 'N/A';
				form.code_postal = this.form.zip ? this.form.zip : 'N/A';
				form.country = 'N/A';
				form.region = 'N/A';
				form.lat = 0;
				form.lng = 0;
			}

			this.form.address = this.form.address ? this.form.address : '---';
			this.form.city = this.form.city ? this.form.city : 'N/A';
			this.form.zip = this.form.zip ? this.form.zip : 'N/A';
			this.form.country = this.form.zip ? this.form.zip : 'N/A';
			this.form.comment = this.form.comment ? this.form.comment : 'N/A';
			this.form.code_postal = this.form.code_postal
				? this.form.code_postal
				: 'N/A';
			this.form.lat = this.form.lat ? this.form.lat : 0;
			this.form.lng = this.form.lng ? this.form.lng : 0;

			return {
				restaurantId:
					this.ownerCommands &&
					this.ownerCommands.length > 0 &&
					this.ownerCommands[0].products &&
					this.ownerCommands[0].products.length > 0
						? this.ownerCommands[0].products[0].restaurantId
						: null,
				commandId: this.command ? this.command.id : null,
				commandForm: {
					commands: this.hash.hash
						? this.ownerCommands
						: this.commands,
					codePromo: this.codePromo
				},
				addressForm: form
			};
		},
		validate: function() {
			let promise = Promise.resolve();
			if (!this.user) promise = this.createUser();
			promise.then(() => {
				this.payCommand().then(() => {
					this.form = {};
					this.refreshCart();
					this.$notify.success({
						title: 'Your demand was successful',
						position: 'top center'
					});
					this.$router.push({ name: 'user-commands' });
				});
			});
		}
	},
	computed: {
		...mapGetters({
			currency: 'lang/getCurrency',
			user: 'auth/user',
			commands: 'cart/commands',
			total: 'cart/total',
			hash: 'cart/hash',
			inviteCards: 'cart/inviteCards',
			command: 'cart/command',
			additionalInfo: 'cart/additionalInfo'
		}),
		reductionValue: function() {
			if (this.reduction) {
				let explodedValue = this.reduction.value.split('%');
				if (explodedValue.length > 1) {
					return Number(this.total * (explodedValue[0] / 100));
				} else if (explodedValue.length == 1) {
					return Number(explodedValue[0]);
				} else {
					return 0;
				}
			} else {
				return 0;
			}
		},
		ownerCommands: function() {
			return this.commands.filter(
				el =>
					!el.clientId ||
					(this.user && el.clientId == this.user.client.id)
			);
		}
	}
};
</script>

<style scoped>
input {
	border: none;
}
</style>
